<template>
    <!-- si le chargement de toutes les image du tableu -->
  <v-main v-if="img_Barre.length">
        <!-- slide barre nos partenaires-->
        <h4 class="txt-bleu text-uppercase">
          <span>Ils nous ont fait confiance :</span>
        </h4>
        <v-row>
          <div class="container py-5">
            <agile :options="myOptions" class="slide">
              <div v-for="(img, id) in img_Barre" :key="id">
                <v-img :src="PhotoPath+'uploadPartenaire/'+img.PhotoFileName"> </v-img>
              </div>
              <template slot="prevButton">prev</template>
              <template slot="nextButton">next</template>
            </agile>
          </div>
        </v-row>

        <!-- --------------------- -->
  </v-main>
</template>
<script>
import { VueAgile } from "vue-agile";
import axios from "axios";

const API_URL = "https://horizonequipement.ma/";
const PHOTO_URL = API_URL+"Vuejs-PHP/src/API/";

export default {
  name: "Nosreference",
  agile: VueAgile,

  data() {
    return {
      myOptions: {
        navButtons: false,
        fade: false,
        autoplaySpeed: 3000,
        centerMode: true,
        initialSlide: 0,
        slidesToShow: 4,
        autoplay: true,
        infinite: true,

        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: false,
            },
          },

          {
            breakpoint: 900,
            settings: {
              navButtons: true,
              arrows: true,
              dots: true,
              infinite: true,
            },
          },
        ],
      },

      //slide_Barre: "img_Barre",
      img_Barre: [],
      id: 0,
      img:"",
      PhotoPath: PHOTO_URL,
      PhotoFileName:"",
    };
  },

  methods: {
      // **** Recuperer la liste des Partenaires

     getDataPartenaire() {
       axios.get(PHOTO_URL + "data.model_Select_Slide.php?action=getSlidePartenaire")
         .then((response) => {
           this.img_Barre = response.data.NamePartenaire;
          
         });
     },
  },
  mounted: function () {
    this.getDataPartenaire();
  
  }, 
};
</script>
<style src="@/assets/css/style.css"></style>
