<template>
<v-main>
      <router-view />
    <Dashboard />
    <v-container>
        <ProductApp /> 
    </v-container>
</v-main>
   
</template>

<script>

import Dashboard from '@/dashboard/views/Dashboard.vue';
import ProductApp from '@/dashboard/components/ProductApp.vue';

export default {
    name: 'Products',
    components: {
        Dashboard,
        ProductApp,
       
    }
    
}
</script>